import $ from 'jquery';
import 'slick-carousel';

$(document).ready(() => {
  // トップページのスライダー
  $('.js-top-slider').slick({
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    arrows: false,
  });

  // メニューの開閉
  $('.js-header-buttonOpen').on('click', function(){
    $('.js-nav').addClass('is-open');
    $('.js-nav').slideDown();
    $('.js-header-buttonOpen').fadeOut();
    $('.js-header-buttonClose').fadeIn();
  });

  $('.c-header-buttonClose').on('click', function(){
    $('.js-nav').removeClass('is-open');
    $('.js-nav').slideUp();
    $('.js-header-buttonOpen').fadeIn();
    $('.js-header-buttonClose').fadeOut();
  });

  // #で始まるリンクをクリックしたら実行する
  // スムーズにスクロールする
  $('a[href^="#"]').click(function(){
    var speed = 1000; // スクロールの速度
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
});

export default class Carousel {
  constructor(selector) {
    this.$el = $(selector);
    this.init();
  }

  init() {
    this.$el.slick({
      dots: true,
      autoplay: true
    });
  }
}
